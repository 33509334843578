<script setup>
defineProps({ blok: Object });

const handleScroll = () => {
  const gradientBoxWrap = document.querySelector(".hero-gradient-box-wrap");
  const productConatainerBoxWrap = document.querySelector(
    ".product-container .content-holder",
  );
  const referenceBoxWrap = productConatainerBoxWrap || gradientBoxWrap;

  const offset = productConatainerBoxWrap ? 400 : 0;

  const startWidth = 110;
  const endWidth = 90;
  const startOpacity = 0.5;
  const endOpacity = 1;

  if (referenceBoxWrap) {
    const bounding = referenceBoxWrap.getBoundingClientRect();

    const isInView =
      bounding.bottom - offset <=
      (window.innerHeight || document.documentElement.clientHeight);

    if (isInView) {
      // Calculate the scroll position relative to 100px
      const maxScroll = window.innerHeight / 2;
      const currentScroll = window.scrollY - bounding.height;

      // Clamp the scroll value between 0 and 100
      const clampedScroll = Math.min(maxScroll, Math.max(0, currentScroll));

      // Calculate the width based on the clamped scroll value

      const width =
        startWidth - (startWidth - endWidth) * (clampedScroll / maxScroll);

      // Calculate the opacity based on the clamped scroll value

      const opacity =
        startOpacity +
        (endOpacity - startOpacity) * (clampedScroll / maxScroll);

      // Apply the calculated opacity to the element
      gradientBoxWrap.style.opacity = opacity;

      // Apply the calculated width to the element
      gradientBoxWrap.style.width = `${width}%`;
      gradientBoxWrap.style.opacity = opacity;
    } else {
      gradientBoxWrap.style.width = `${startWidth}%`;
      gradientBoxWrap.style.opacity = startOpacity;
    }
  }
};

onMounted(() => {
  handleScroll();
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
  <div v-editable="blok" class="main-section-hero">
    <BgHomeGradient v-if="!blok?.no_gradient" />

    <div
      :class="{
        'container no-padding': blok.image?.filename,
        'hero-main-container': !blok.image?.filename,
      }"
    >
      <div
        :class="{
          'flex-none md:flex flex-row': blok.image?.filename,
          'hero-small-container': !blok.image?.filename,
        }"
      >
        <div
          class="hero-01-content align-start"
          :class="{
            'text-left w-full md:w-3/5 ml-5 mt-5': blok.image?.filename,
            'text-center': !blok.image?.filename,
          }"
        >
          <div style="max-width: 700px">
            <HeroHeader :blok>
              <slot name="header" />

              <template #buttons>
                <slot name="buttons" />
              </template>
            </HeroHeader>
          </div>
        </div>
        <div
          v-if="blok?.image?.filename"
          class="w-full md:w-2/5 flex items-end"
        >
          <NuxtImg
            sizes="sm:200px md:300px lg:500px"
            :src="blok.image.filename"
            :alt="blok.image.alt"
            class="keyvisual-image mx-auto"
            :class="{
              'self-center mb-5 md:mb-0': blok.image_centered,
              'rounded rounded-lg w-100': blok.image_rounded,
            }"
          />
        </div>
      </div>
    </div>

    <div
      v-if="blok.blocks?.length"
      class="home-hero-container"
      :class="{ 'my-10': blok.space_between_contents }"
      data-animate="fadeIn"
      data-animate-delay="3"
    >
      <div
        data-w-id="d6116216-2018-c43f-c07a-82f001922b4d"
        class="hero-product-demo"
      >
        <HeroProductDemo logos>
          <StoryblokComponent
            v-for="subBlok in blok.blocks"
            :key="subBlok._uid"
            :blok="subBlok"
            :parent-blok="blok"
          />
        </HeroProductDemo>
      </div>
    </div>

    <slot />
  </div>
</template>

<style scoped>
.main-section-hero {
  z-index: 0;
}
.product-container {
  padding-bottom: 150px;
}
.hero-product-demo {
  height: auto;
}
.home-hero-container {
  height: auto;
}
.keyvisual-image {
  max-height: 600px;
  object-fit: cover;
  object-position: left bottom;

  &.w-100 {
    max-height: 500px;
    object-position: center;
  }
}
</style>
